"use strict";

require('./styles.css');

var ClipboardJS = require('clipboard');

exports.onClientEntry = function () {
  new ClipboardJS('.codebtn', {
    text: function text(trigger) {
      trigger.className = trigger.className + ' blink-2';
      var code = decodeURI(trigger.getAttribute('data-code'));
      return code;
    }
  });

  window.showClipboardToaster = function (toasterId) {
    var textElem = document.querySelector("[data-toaster-id=\"".concat(toasterId, "\"]"));

    if (!textElem) {
      return;
    }

    var el = document.createElement('div');
    el.className = textElem.dataset.toasterClass;
    el.innerHTML = "\n      <div class=\"".concat(textElem.dataset.toasterTextClass, "\">\n        ").concat(textElem.dataset.toasterText, "\n      </div>\n    ").trim();
    document.body.appendChild(el);
    setTimeout(function () {
      document.body.removeChild(el);
    }, textElem.dataset.toasterDuration);
  };
};